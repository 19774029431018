angular.module("LeasePilot").controller("ManageBuildingsController", [
  "$scope",
  "$mdDialog",
  "BuildingService",
  "BuildingHelperService",
  function($scope, $mdDialog, BuildingService, BuildingHelperService) {
    $scope.init = async function() {
      $scope.isProcessing = false;
      $scope.buildings = await BuildingService.get();
    };

    $scope.initEditBuilding = function(building) {
      $scope.building = building;
    }

    $scope.validateBeforeCreate = async function() {
      const isValid = await BuildingHelperService.validate({
        type: "create",
        name: $scope.building.displayName || "",
        dashboardName: $scope.building.dashboardName || "",
        landlordName: $scope.building.landlordName || "",
        landlordEntityType: $scope.building.landlordEntityType || "",
        landlordStateOfFormation: $scope.building.landlordStateOfFormation || "",
        streetAddress: $scope.building.streetAddress || "",
        city: $scope.building.city || "",
        county: $scope.building.county || "",
        state: $scope.building.state || "",
        zipCode: $scope.building.zipCode || "",
        municipalityType: $scope.building.municipalityType || "",
        buildingOfficeArea: $scope.building.buildingOfficeArea,
      });

      $scope.safeApply(() => {
        $scope.isValid = isValid;
      });
    };

    $scope.validateBeforeUpdate = async function() {
      const isValid = await BuildingHelperService.validate({
        type: "update",
        name: $scope.building.displayName || "",
        dashboardName: $scope.building.dashboardName || "",
        landlordName: $scope.building.landlordName || "",
        landlordEntityType: $scope.building.landlordEntityType || "",
        landlordStateOfFormation: $scope.building.landlordStateOfFormation || "",
        streetAddress: $scope.building.streetAddress || "",
        city: $scope.building.city || "",
        county: $scope.building.county || "",
        state: $scope.building.state || "",
        zipCode: $scope.building.zipCode || "",
        municipalityType: $scope.building.municipalityType || "",
        buildingOfficeArea: $scope.building.buildingOfficeArea,
      });

      $scope.safeApply(() => {
        $scope.isValid = isValid;
      });
    };

    $scope.showDeleteBuildingDialog = function(building) {
      $scope.currentBuilding = building;
      $mdDialog.show({
        template: `<md-dialog><delete-building-confirmation-modal id="${building.id}" name="${building.dashboardName}" /></md-dialog>`,
        parent: angular.element(document.body),
        fullscreen: false
      });
    };

    $scope.create = async function() {
      const isValid = await BuildingHelperService.validate({
        type: "create",
        name: $scope.building.displayName || "",
        dashboardName: $scope.building.dashboardName || "",
        landlordName: $scope.building.landlordName || "",
        landlordEntityType: $scope.building.landlordEntityType || "",
        landlordStateOfFormation: $scope.building.landlordStateOfFormation || "",
        streetAddress: $scope.building.streetAddress || "",
        city: $scope.building.city || "",
        county: $scope.building.county || "",
        state: $scope.building.state || "",
        zipCode: $scope.building.zipCode || "",
        municipalityType: $scope.building.municipalityType || "",
        buildingOfficeArea: $scope.building.buildingOfficeArea,
      });

      $scope.isValid = isValid;

      if (isValid.all) {
        $scope.safeApply(() => {
          $scope.isProcessing = true;
        });
        
        const promise = BuildingHelperService.create({
          name: $scope.building.displayName,
          dashboardName: $scope.building.dashboardName,
          landlordName: $scope.building.landlordName,
          landlordEntityType: $scope.building.landlordEntityType,
          landlordStateOfFormation: $scope.building.landlordStateOfFormation,
          streetAddress: $scope.building.streetAddress,
          city: $scope.building.city,
          county: $scope.building.county,
          state: $scope.building.state,
          zipCode: $scope.building.zipCode,
          municipalityType: $scope.building.municipalityType,
          buildingOfficeArea: $scope.building.buildingOfficeArea,
        });
        
        promise.then(
          response => {
            window.location.href = "/manage_forms/buildings";
          },
          error => {
            console.log(error);
            // TODO:
          }
        );
      }
    };

    $scope.update = async function() {
      const isValid = await BuildingHelperService.validate({
        type: "update",
        name: $scope.building.displayName || "",
        dashboardName: $scope.building.dashboardName || "",
        landlordName: $scope.building.landlordName || "",
        landlordEntityType: $scope.building.landlordEntityType || "",
        landlordStateOfFormation: $scope.building.landlordStateOfFormation || "",
        streetAddress: $scope.building.streetAddress || "",
        city: $scope.building.city || "",
        county: $scope.building.county || "",
        state: $scope.building.state || "",
        zipCode: $scope.building.zipCode || "",
        municipalityType: $scope.building.municipalityType || "",
        buildingOfficeArea: $scope.building.buildingOfficeArea,
      });

      $scope.isValid = isValid;

      if (isValid.all) {
        $scope.safeApply(() => {
          $scope.isProcessing = true;
        });

        const promise = BuildingHelperService.update({
          id: $scope.building.id,
          name: $scope.building.displayName,
          dashboardName: $scope.building.dashboardName,
          landlordName: $scope.building.landlordName,
          landlordEntityType: $scope.building.landlordEntityType,
          landlordStateOfFormation: $scope.building.landlordStateOfFormation,
          streetAddress: $scope.building.streetAddress,
          city: $scope.building.city,
          county: $scope.building.county,
          state: $scope.building.state,
          zipCode: $scope.building.zipCode,
          municipalityType: $scope.building.municipalityType,
          buildingOfficeArea: $scope.building.buildingOfficeArea,
        });
        
        promise.then(
          response => {
            window.location.href = "/manage_forms/buildings";
          },
          error => {
            console.log(error);
            // TODO:
          }
        );
      }
    };

    $scope.createSupportTicket = function() {
      zendesk.state.go("zendesk");
      zendesk.sidenav.open();
    }

    $scope.cancel = function() {
      window.location.href = "/manage_forms/buildings";
    };
  }
]);
